.body_project {
    background-color:  #f4f4f4;
    min-height: 500px;
}

.cardTitle {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 0.5rem;
}

.cardDesc {
    font-size: .85rem;
    color: black;
    font-weight: 500;
    
}

.cardImg {
    height: '150px';
    overflow: 'hidden';
    display: 'flex';
    align-items: 'start';
    justify-content: 'center'
}

.title {
    font-size: 1.2rem;
    color: #000;
}

.card_custom {
    background-color: #f8f8f8ad;
    color: black
}

.card_custom:hover {
    transform: scale(1.03);
    background-color: #e9e9e9ad;
}

.cardStatusStarted {
    color: #409c16;
    margin-left: 1rem;
    font-weight: 500;
}

.untilDate {
    margin-top: -1em;
    color: #000000;
    margin-left: 1rem;
    font-weight: 500;
}

.cardStatusNotStarted {
    color: #d22d10;
    margin-left: 1rem;
    font-weight: 500;
}   

.backAba {
    background-color: white;
    margin-top: 0 !important;
    padding: 0 !important;
    min-height: 490px;
}

.abaItemsInactive {
    background-color: rgb(206, 206, 206);
    font-weight: 500;
}

.abaItems {
    font-size: 1em;
    font-weight: 500;
    color: #265287;
    background-color: white;
}

.cardStatusExpired {
    margin-left: 1rem;
}

.logoScormAI {
    width: 80%;
}