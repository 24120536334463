.videoOverlay {
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    backdrop-filter: blur(10px);
}

.playIcon {
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 50px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: end;
    margin-left: 5%;
}

.buttons {
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 25px;
    width: 15%;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    background-color: #265287;
    border: 2px solid #1d5daa;
    border-radius: 10px;
}

.objectTitle {
    margin-left: 5%;
    font-size: 40px;
    font-weight: bold;
    color: yellow;
}

.formatContent {
    margin-top: 5%;
    margin-left: 5%;
}

.formatCase {
    margin-top: 0%;
    margin-left: 5%;
}

.contentText {
    color: rgb(255, 255, 255);
    background-color: transparent;
    border: none;
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
}

.supplementaryContent {
    color: rgb(255, 255, 255);
    background-color: transparent;
    border: none;
    font-size: 15px;
    margin-top: 10px;
}

.questionOption {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 2%;
    color: rgb(255, 255, 255);
    font-size: 20px;
}

.textArea {
    width: 70%;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    box-shadow: none;
}

.radioInput {
    cursor: pointer;
}

.radioInput:checked {
    background-color: cornflowerblue;
    border-color: darkblue;
}

.resultQuestions {
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.5em;
}

.correctIcon {
    color: rgb(13, 224, 13);
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icons {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconDelete {
    font-size: 2em;
    color: white
}

.buttonX {
    background-color: transparent;
    border: none;
    cursor: pointer;

}