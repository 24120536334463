.div_choosefile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  background-image: url("../../images/bgMenu.jpg");
  background-size: cover;
  min-height: 100%;
}

.row_items {
  justify-content: center;
  text-align: center;
}

.login {
  text-align: center;
}
  
  .login p {
    color: #aaa;
  }

  .btn_style {
    background-color: #4785d1;
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 20em;
    height: 2.5em;
  }

  .btn_google {
    background-color: white;
    border: 1px solid #265287;
    border-radius: 6px;
    color: #265287;
    font-weight: bold;
    width: 20em;
    height: 2.5em;
  }

  .icon_user {
    color: #ffffff;
    font-size: 90px;
  }

  .texts {
    color: #ffffff;
   }
  
   .textsHyperlink {
    color: #53d5f9;
    font-weight: bold;
   }

  .form_field {
    width: 20em;
    height: 2.5em;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }

  .google_login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }


  