.navbar {
    display: flex;
    align-items: center;
    padding: 0.5em 2em;
  }

  .brand {
    font-size: 1.5em;
  }

  .logo {
    width: 130px;
  }

  .menu {
    color: #265287;
    font-weight: 500;
  }

  .links_list {
    display: flex;
    list-style: none;
  }

  .active {
    color: #265287;
    font-weight: bold;
    text-decoration: none;
    font-size: 16px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
  }

  .active:hover {
    color: white;
    background-color: #265287;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
  }

  .logoutButton {
    display: flex;
    align-items: center;
  }

  .logoutButton button {
    display: flex;
    align-items: center;
  }

  .buttons {
    background-color: transparent;
    border: none;
  }
  
  .selected {
    color: white!important;
    background-color: #265287;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
    font-weight: bold;
    text-decoration: none;
    font-size: 16px;
  }

  .not_active {
    text-decoration: none;
    font-weight: bold;
    color: #069ed9;
    font-size: 17px;
  }

  .thumbnailCircular {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #000000;
    margin-right: 10px;
  }
  
  .iconPerson {
    font-size: 2rem;
    margin-right: 10px;
  }