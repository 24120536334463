.btn_google {
  background-color: white;
  border: 1px solid #265287;
  border-radius: 6px;
  color: #265287;
  font-weight: bold;
  width: 20em;
  height: 3em;
}

.row_items {
  justify-content: center;
  text-align: center;
  text-align: center;
}

.full_width_video {
  width: 100%;
  height: auto;
}

.video {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carrousel_label {
  font-size: 25px;
  color: #265287; 
  font-weight: bold;
}

.carousel_next_icon {
  display: flex;
  justify-content: end;
}

.carouselControls_next {
  display: flex;
  justify-content: end;
}

.carouselControls_prev {
  display: flex;
  justify-content: left;
}

.carouselIndicators {
  margin-bottom: -3rem;
}