

.btn_style {
    background-color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 15em;
    height: 2.5em;
    margin-top: 20px;
  }

  .form_field {
    height: 2.5em;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }