.card_config {
    background-color: white;
}

.button_quizz {
    border: 1px solid rgb(39, 189, 244);
    background-color: rgb(39, 189, 244);
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.progressContainer {
    height: 25px;
    border-radius: 15px;
    margin-top: 50%;
}

.subTitle {
    color: black;
    text-align: center;
    white-space: pre-line;
}

.form_parameters {
    width: 13.5em;
    height: 2.5em;
    color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }

  .adjust_width {
    width: 20em;
  }

  .btn_style {
    background-color: #f3b012e1;
    border: 1px solid #f3b012e1;
    border-radius: 6px;
    color: black;
    font-weight: bold;
    width: 17em;
    height: 2.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .btn_style:disabled {
    background-color: #f6da9ae1;
    border: 1px solid #f6da9ae1;
    color: rgb(143, 140, 140);
  }

  .form_field {
    width: 20em;
    color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }

  .qty_question {
    color: black;
    font-weight: 500;
  }

  .ia_limit {
    margin-top: 2em;
    margin-right: 1em;
  }

  .option_check {
    color: #265287;
    border: 1px solid #265287;
  }

  .btn_style_select {
    background-color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 15em;
    height: 2.5em;
  }

  .fileName {
    color: #265287;
    font-size: 20px;
  }

 .clearFile {
  background-color: transparent;
  border: none;
  cursor: pointer;
 }

 .icon_clear {
  color: red;
  font-size: 18px;
  font-weight: bold;
 }