.btn_plus {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 30px;
}

.icon_plus {
  display: flex;
  font-size: 30px;
}

.new_topic {
  width: 600px;

}

.icon_arrow {
  cursor: pointer;
  display: flex;
  font-size: 30px;
}

.closeTopic {
  font-size: large;
  font-weight: bolder;
  color: red;
  background-color: transparent;
  border: none;
}

.createTopic {
  font-size: large;
  font-weight: bolder;
  color: green;
  background-color: transparent;
  border: none;
}

.topics_name {
  width: 40em;
}

.option_check {
  color: #265287;
  border: 1px solid #265287;
}

.option_check:checked {
  background-color: #265287;
  border-color: #265287;
}

.topicText {
  font-size: 16px;
  color: black;
}

.form_field {
  width: 15em;
  height: 2.5em;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.inline_items {
  display: flex;
  align-items: center;

}

.texts_format {
  color: black;
}