.btn_style {
    background-color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 15em;
    height: 2.5em;
  }

  .form_field {
    width: 20em;
    height: 2.5em;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }

  .icon_info {
    color: #265287;
  }

  .alert_costumization {
    background-color: #265287;
    border-color: black;
    color: white;
  }

  .info_boxes {
    position: absolute;
    margin-left: 24em;
    margin-top: 2em;
  }

  .info_boxes_preferences {
    position: absolute;
    margin-left: 15%;
    margin-top: -5%;
  }