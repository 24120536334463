.iconsEnroll {
    cursor: pointer;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.filters {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 100%;
    outline: none;
    box-shadow: none;
}

.filterDate {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 70%;
    outline: none;
    box-shadow: none;
    padding: 0%;
    font-weight: bold;
}

.filters:focus,
.filterName:focus,
.filterDate:focus
 {
    outline: none;
    box-shadow: none;
}

.filterName {
    padding: 3px;
    border: 1px solid rgb(179, 178, 178);
    font-size: 16px;
    width: 100%;
    border-radius: 2px;
}