.logo {
    width: 120px;
    margin-right: 5px;
  }

  .btn_style {
    background-color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 17em;
    height: 2.5em;
  }

  .form_email {
    width: 25em;
    height: 2.5em;
    text-indent: 10px;
  }

  .btn_cancel {
    background-color: white;
    border: 1px solid #265287;
    border-radius: 6px;
    color: #265287;
    font-weight: bold;
    width: 11em;
    height: 2.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }