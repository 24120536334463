.bg {
    background-color: #f9f9f9;
    color: #323850;
  }
  .secao {
    background-color: #ffffff;
    margin: 2em;
    min-height: 12em;
  }
  
  /* Animação de fade-in */
.fadeIn {
  opacity: 1!important;;
  transform: translateY(0)!important;;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out!important;;
}

.hideFadeIn {
  opacity: 0;
  transform: translateY(20px);
}
  
  .call_to_action .area {
    filter: drop-shadow(0 0);
    position: absolute;
    top: 0;
    margin: 3.5em 2em;
  }

    
    .call_to_action {
      background: url('../../images/legal.jpg') center/cover no-repeat!important;
      padding: 7em 0!important;
      background-position-y: bottom!important;
      position: relative;
      color: #ffffff;
      margin: 0;
      text-align: center;
      height: 21em;
      max-height: 22em;
    }
    
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(38,82,135);
      background: -moz-linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
      background: -webkit-linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
      background: linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#265287",endColorstr="#265287",GradientType=1);
    }
    
    .call_to_action h1 {
      font-size: 3.5rem;
    /* margin-bottom: 1rem; */
    font-weight: bolder;
    margin: 1em auto;
    }
    
    .call_to_action h6 {
      font-weight: normal;
    }
    
    .call_to_action p {
      font-size: 1.2rem;
    }
    
    .call_to_action .btn {
      margin: 1em 0;
    }

    
.cabecalho {
  z-index: 9999;
  position: relative;
}
.cabecalho h1, h2 {
  overflow: hidden;
}

    .d_none {
        display: none !important;
      }
    
      .p_2 {
        padding: 0.5rem !important;
      }
    
      
     .legais {
        /*margin: 0 auto;*/
         padding: 1em;
        background-color: #ffffff;
        margin: 1em auto 4em auto;
        filter: drop-shadow(5px 5px 10px #cccccc);
      }

      .text_left {
        text-align: left!important;
      }
      
      @media (min-width: 576px) {
        .area {
          max-width: 540px;
        }
        .d_sm_none {
          display: none !important;
        }
        .d_sm_block {
          display: block !important;
      }
      }
      @media (min-width: 768px) {
        .area {
          max-width: 720px;
        }
        .text_md_left {
          text-align: left!important;
        }
        .text_md_right {
          text-align: right!important;
        }
        .col_md_8 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
          max-width: 66.666667%;
        }
        .col_md_7 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 58.333333%;
          flex: 0 0 58.333333%;
          max-width: 58.333333%;
        }
        .col_md_6 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }
        .col_md_4 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }
        .col_md_3 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
        }
        .col_md_2 {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 16.666667%;
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
        }
        .mb_md_0, .my_md_0 {
          margin-bottom: 0 !important;
        }
        .d_md_block {
          display: block !important;
          height: auto;
          width: auto;
        }
        .d_md_none {
          display: none !important;
        }
        
      }
    
      @media (min-width: 992px) {
        .area {
          max-width: 960px;
        }
      }
      @media (min-width: 1200px) {
        .area {
          max-width: 1140px;
        }
      }
      @media (min-width: 1400px) {
        .area {
          max-width: 1320px;
        }
      }
    

.botao {
  top:inherit;
}


.bg button {
  background-color:  #32394b!important;
  width: 0.6em!important;
  height: 0.6em!important;
  border-radius: 18em;
  border: 1px solid #28518500!important;
}

/* --- LEGAIS --- */
.legais h1, .legais h2, .legais h3, .legais h4, .legais h5, .legaisbg h6 {
  padding: 0.5em 0;
  margin: 0.5em 0 0 0;
}

.legais h2 {font-size: 1.7rem;}
.legais h3 {font-size: 1.3rem;}

.legais p {
 padding: 0.5em 0; 
}

.legais ul {
padding: 0;
}
.legais ul, .legais ol, .legais li   {
  list-style-type: disc;
}

.legais ul h3, .legais ul h4  {
color: #6E4F75;
margin-bottom: -0.3em;
}

.legais ul li {
margin-left: 1.3em;
}

.legais .legais ol {
list-style-type: disc;
}

.legais a {
color:inherit;
text-decoration-line: underline;
/*text-decoration-line: none;*/
}

.legais .destaque {
color: #b56482;
}

.legais .botoesChamadas {padding:1em 0 2em 0; margin-bottom: 11px; z-index: 4;}
.legais .botoesChamadas h2 {margin-top: -7px; font-weight: lighter; padding: .5em 0}

.legais .botoesChamadas a {
margin: 0 0.5em;
min-width: 19em;
}

.legais #parte1 h1{
margin: 0;
}
.scrollToTop {
position: fixed;
bottom: 20px;
right: 20px;
cursor: pointer;
display: none;
z-index: 4;
}


.arrow {
width: 40px;
height: 40px;
background-color: #333;
fill: #fff;
border-radius: 50%;
padding: 10px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.botoesChamadas {
  background-color: #323850;
  margin: 2em 0;
  color: #ffffff;
  padding: 3em 0 4em 0;
  margin-bottom: 0;
}

.botoesChamadas a {
  margin: 0 0.5em;
  min-width: 19em;
}
.btn_primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}
.btn_primary, .btn_other {
  font-weight: 500;
  padding: 0.75rem 2rem;
  border-radius: 0.5em;
}
.btn_primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn_other:hover {
  background-color: #514E6C;
  border-color: #514E6C;
  color: #fff;
}
@media (max-width: 1217px) {
  .botoesChamadas a {
    margin: 1em;
  }

}
