.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search_form {
  max-width: 100%;
  width: 60%;
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.search_form input {
  margin-right: 10px;
  width: 50%;
}

.noposts {
  text-align: center;
}

.noposts p {
  margin-bottom: 1.5em;
}

.noposts a {
  padding: 10px 25px;
}

.folder_icon {
  color: white;
  display: flex;
}

.folder_icon_x {
  font-size: 25px;
  display: flex;
  color: white;
}

.folders {
  cursor: pointer;
  text-decoration: none;
}

.folders_name {
  width: 20em;
  color: white;
}

.type_folder {
  width: 20em;
  height: 3em;
  border: none;
  background-color: transparent;
  color: white;
  outline: none;
  font-size: 30px;
}

.type_folder::placeholder {
  color: white; 
  font-size: 20px;
}

.type_folder:focus {
  outline: none;
}

.closeFolder {
  font-size: 30px;
  font-weight: bold;
  color: rgb(239, 91, 91);
  background-color: transparent;
  border: none;
}

.createFolder {
  font-size: 40px;
  font-weight: bold;
  color: rgb(55, 231, 55);
  background-color: transparent;
  border: none;
}

.createFolder:disabled {
  background-color: transparent;
  color: rgb(203, 227, 203);
}

.circle_icon {
  margin-top: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 50%;
  background-color: white;
  color: black;
  background: rgb(250, 250, 250);
  background: linear-gradient(180deg, rgba(250, 250, 250, 1) 14%, rgba(227, 227, 227, 1) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.arrow_icon {
  margin-left: 2px;
}

.projects_icon {
  font-size: 20px;
}

.buttons_projects_icon {
  border: none;
  background-color: transparent;
  color: #1f6fd9
}

/*número dentro das etapas*/
.bi {
  opacity: 0.5;
  font-size: 10px;
  color: rgb(194, 194, 194);

}

.current_icon {
  font-size: 40px;
  color: rgb(255, 166, 0);
}

.did_icon {
  font-size: 40px;
  color: rgb(30, 205, 30);
}

.icon_row {
  align-items: top;
  justify-content: center;
  position: relative;
  text-align: center;
  top: 0;
  background-color: #fbfbfb;
  border-radius: 90px;
  padding: 6px;
}

h2 {
  margin-top: 30px;
  margin-bottom: 30px;
}


h5 {
  color: rgb(181, 181, 181);
  font-size: 13px;
}

h6 {
  color: black;
}

.txt_verde {
  color: rgb(30, 205, 30);
  font-size: 13px;
}

.txt_laranja {
  color: rgb(255, 166, 0);
  font-size: 13px;
}

.div_project_settings {
  width: auto;
  height: auto;

}

.flag {
  width: 30px;
}

.flag_english {
  width: 60px
}

.bnt_new {
  background-color: #e3f2fd;
  border: 1px solid #1f6fd9;
  border-radius: 4px;
  height: 2 em;
}

.line {
  color: #265287;
  border-width: 3px;
}

.btn_style {
  background-color: #265287;
  border: 1px solid #265287;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  width: 10em;
  height: 3em;
}

.orange {
  background-color: #B56482;
  border: 1px solid #B56482;
}

.form_field {
  width: 20em;
  height: 2.5em;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.round_pill {
  background-color: white;
  border-radius: 20px;
  border: 3px solid #265287;
  height: 2em;
  width: 10em;
}

.text_plan {
  color: #265287;
  font-weight: bold;
}

.progress_bar_customize {
  background-color: #265287;
}

.my_plan {
  color: #265287;
  font-weight: bold;
}

.main_background {
  margin: auto 0;
  padding: .1em !important;
  background-image: url("../../images/bgMenu.jpg");
  color: #fff;
  min-height: 500px;
  background-size: cover;
}

.folder_plus {
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.card_background {
  background-color: #012c61a3;
  color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  transition: all 0.3s ease;
  cursor: pointer;
}

.card_background:hover {
  transform: scale(1.03);
  background-color: #0253a3ad;
  z-index: 90;
}

.text_colors {
  color: white;
}

.dd_zindex {
  margin-top: -120px;
  margin-left: 30%;
  display: flex;
  margin-right: 100px;
  border: 1px solid #ffffff;
  background-color: #012c61a3;
  border-radius: 40px;
  transition: all 0.3s ease;
}

.dd_zindex:hover {
  transform: scale(1.03);
  background-color: #0253a3ad;
  z-index: 90;
}

.force_hover {
  transform: scale(1.03);
  background-color: #0253a3ad;
  z-index: 90;
}

.icons_row {
  padding: 0.5em;
  margin: 0.5em 2em;
}

.icon_texts {
  color: white;
  font-size: 1em;
  position: absolute;
  white-space: nowrap;
  margin-top: -7px;
}

/* --- MEUS PROJETOS --- */
.projects, .btnBackFolder {
  background: #ffffff24;
  border-radius: 1em;
  padding: 1em;
  margin: 0.5em 1em;
  max-height: 8.5em;
  min-height: 8.5em;
  cursor: pointer;
  transition: all 0.3s ease;
}
.projects_disabled {
  background: #ffffff24;
  border-radius: 1em;
  padding: 1em;
  margin: 0.5em 1em;
  max-height: 8.5em;
  min-height: 8.5em;
  transition: all 0.3s ease;
}
.btnBackFolder {
  background: #1d457600;
}
.projects:hover, .btnBackFolder:hover {
  background: #ffffff4a;
   transform: scale(1.01);
}
.myProjects {
  max-width: 90em;
  margin: 0 auto;
}
.MyProjects_folders_name {
  padding: 1.5em 0 .5em 0
}
.mainMenu h2 {
  text-transform: uppercase;
  font-size: 1.5em;
}

.MyProjects_folders_name  h2, .MyProjects_project_name  h2 {
  font-size: 20px;
  margin: 0;
}

.MyProjects_folders_name ul, .esconde ul, .MyProjects_project_name ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: inherit;
}
.MyProjects_folder_icon img {
  max-height: 84%;
}

.tipo img {
  max-height: 1.5em;
  margin-top: -3px;
}
.esconde {
  display: none!important;
  position: absolute;
  border-radius: 1em;
  padding: .5em;
  margin: 0 auto;
  top: 100%; 
  right: 0;
  width: 100%;
  max-height: 8.5em;
  min-height: 8.5em;
  z-index: 49;
  opacity: 0; 
  background-color: #1a4273d1;
  color: #ffffff;
  border: solid .5px;
  transition: opacity 0.3s ease, top 0.3s ease; 
}

.esconde li:hover {
  font-weight: bold;
}

.mostrar {
  display: block!important;
  opacity: 1; 
  top: 0;
}
.btnMenu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; 
  padding: 10px; 
}

.btnMenu img {
  height: 1.5em;
}

.transparentDiv {
  background-color: transparent;
  visibility: hidden;
}

/* -- SM -- */
@media (max-width: 575.98px) {
.MyProjects_folder_icon img {
      max-height: 50%;
  }
  .MyProjects_project_name {
      padding: 2em;
  }
}
/* -- MD -- */
@media (min-width: 575.99px) and (max-width: 991.98px) {
.MyProjects_folder_icon img {
      max-height: 60%;
  }
}

.disabled_link {
  pointer-events: none;
  color: gray;
  cursor: not-allowed;
  text-decoration: none;
}

.enable_link {
  pointer-events: visible;
  cursor: pointer;
  font-style: normal;

  color: white;
  text-decoration: none;
}
