.body_project {
  margin: 0 auto !important;
  padding-top: 0 !important;
  top: 0 !important;
  border: 0 !important;
  box-sizing: border-box;
  margin: 2em auto;
  background: rgb(0, 201, 183);
  background: -moz-linear-gradient(100deg, rgba(0, 201, 183, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: -webkit-linear-gradient(100deg, rgba(0, 201, 183, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: linear-gradient(100deg, rgba(0, 201, 183, 1) 0%, rgba(0, 131, 221, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00c9b7", endColorstr="#0083dd", GradientType=1);
  font-family: 'Open Sans', 'Trebuchet MS', 'Tahoma', 'Arial';
  font-weight: 300;
}

.subject {
  color: #FFFFFF;
}

.question_feedback {
  margin-top: 2em;
  border-radius: 4em 4em 2em 2em;
  background-color: #020a1887;
  padding: 0 0 1em 0;
}

.question {
  font-weight: 600;
  font-size: 25px;
  color: #ffffff;
  margin-bottom: 1em;
  background: rgb(113, 96, 193);
  background: -moz-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: -webkit-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7160c1", endColorstr="#0083dd", GradientType=1);
  padding: 1em;
  border-radius: 2em 2em 0 0;
  -moz-box-shadow: 0 0 1em #000000e3;
  -webkit-box-shadow: 0 0 1em #000000e3;
  box-shadow: 0 0 1em #000000e3;
}

.question_feedback span {
  text-align: center;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.correct_answer,
.incorrect_answer {
  text-align: center;
  margin-top: 10px;
  width: 100%;
  color: rgb(255, 255, 255);
  font-weight: 600;
  padding: 2em;
  border-radius: 0em 0em 2em 2em;
  margin: 10px 0 -16px 0;
}

.correct_answer {
  background-color: #003a30c9;
}

.incorrect_answer {
  background-color: #a32596cc;
}

.results {
  text-align: center;
  background: rgb(113, 96, 193);
  background: -moz-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: -webkit-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7160c1", endColorstr="#0083dd", GradientType=1);
  font-size: 24px;
  color: white;
  font-weight: bold;
  padding: 1em;
  /* margin-top: 0.5em!important; */
  margin-bottom: 2.5em !important;
  border-radius: 3em;
  max-width: 100%;
  cursor: pointer;
}

.results:hover {
  transform: scale(1.02);
}

.selected_alnl {
  background-color: #009282;
}

.label_assessment {
  background-color: #ffffff38;
  border-radius: 7em;
  min-width: 80%;
  max-width: 80%;
  padding: 1em;
  margin: 0.5em 0 1em;
  color: #ffffff;
  cursor: pointer;
}

.label_selected {
  background-color: #009282;
  border-radius: 7em;
  min-width: 80%;
  max-width: 80%;
  padding: 1em;
  margin: 0.5em 0 1em;
  color: #ffffff;
  cursor: pointer;
}

.label_assessment:hover {
  background-color: #009282;
}

.submitButton {
  background-color: #00C9B7;
  border: 0;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  width: 15%;
  margin-bottom: -1em;
  margin-top: -2em;
  bottom: 0;
  padding: 20px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 2em;
  min-width: 7em;
  max-width: 7em;
}

.submitButton:hover {
  transform: scale(1.05);
}

.project-content,
.form_assessment {
  width: 80%;
  color: #ffffff;
}

.form_assessment {
  margin-top: 2em;
  text-align: center;
  margin-bottom: 4em;
}

.project-content {
  background-color: #020a18c4;
  border-radius: 4em 4em 1em 1em;
  -moz-box-shadow: 0 0 1em #000000e3;
  -webkit-box-shadow: 0 0 1em #000000e3;
  box-shadow: 0 0 1em #000000e3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 2em;
}

.title-line {
  background: rgb(113, 96, 193);
  background: -moz-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: -webkit-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7160c1", endColorstr="#0083dd", GradientType=1);
  padding: 1em;
  border-radius: 2em 2em 0 0;
  font-weight: 600;
  font-size: 25px;
  width: calc(100% + 64px);
  margin-left: -32px;
}

.project-content .title-line {
  text-align: center;
}

.h1_assessment {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px !important;
}

.mensagem {
  color: black;
}

.p_assemssment {
  margin-top: 0;
  margin-bottom: 0;
}

.mensagem {
  border-radius: 2em;
  background-color: #dce4f387;
  padding: 2em;
  -moz-box-shadow: 0 0 1em #000000e3;
  -webkit-box-shadow: 0 0 1em #000000e3;
  box-shadow: 0 0 1em #000000e3;
  min-width: 80%;
  max-width: 80%;
  background: rgb(113, 96, 193);
  background: -moz-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: -webkit-linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  background: linear-gradient(170deg, rgba(113, 96, 193, 1) 0%, rgba(0, 131, 221, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7160c1", endColorstr="#0083dd", GradientType=1);
  color: #ffffff;
  margin: 2em auto;
  font-size: 1.4rem;
  text-align: center;
  margin: 2em auto !important;
}

.footer-image {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateX(-50%);
  z-index: 9999;
  max-width: 100px;
}

#answerAssessment {
  background-color: rgba(0, 131, 221, 1);
  border: 0;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 2em;
  min-width: 5em;
  max-width: 9em;
  display: flex;
  justify-content: center;
  align-items: center;
}

#reviewVideo {
  background-color: rgb(113, 96, 193);
  border: 0;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 2em;
  min-width: 5em;
  max-width: 9em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answerAssessmentVideo {
  font-size: 20px;
  border-radius: 5em;
  min-width: 10em;
  max-width: 10em;
}

.carousel-control-prev-icon {
  color: #FFFFFF;
}

.carousel-control-next-icon {
  color: #FFFFFF;
}

.custom_bar {
  width: 100%;
  background: linear-gradient(100deg, rgba(0, 131, 221, 1) 0%, rgba(0, 201, 183, 1) 100%);
  margin: 0 auto;
  box-shadow: 0 0 1em #344b4775;
}

.top_bar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}

.bottom_bar {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slide_customization {
  border: 2px solid #265287;
  width: 70em;
  height: 35em;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.course_title {
  align-items: center;
  justify-content: start;
  color: aliceblue;
  font-size: 20px;
  display: flex;
  width: 100%;
  padding-left: 10px;
}

.button_navigation {
  z-index: 1000;
  background-color: transparent;
  border: 0;
  font-size: 40px;
  color: aliceblue;
  padding-right: 15px;
}

.button_navigation:disabled {
  background-color: transparent;
  color: rgb(152, 149, 149);
}

.button_intro_carousel {
  background-color: transparent;
  border: none;
}

.button_intro_carousel:disabled {
  background-color: transparent;
  border: none;
}

.carousel_texts {
  color: #265287;
  font-size: 17px;
  padding: 1em 0;
}

.progressBarContainer {
  background-color: #98989f;
  height: 20px;
  border-radius: 5px;
  position: relative;
}

.progressBar {
  height: 20px;
  background-color: #5e7085;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.progressBarEnd {
  border-radius: 5px;
}

.progressTextContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 700;
}

.menu_adjust {
  width: 80%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.menu_icon {
  font-size: 30px;
}

.logo {
  width: 15%;
}

.adjust_align {
  justify-content: center;
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }

  .text-md-left {
    text-align: left !important;
  }
}

:root {
  --bs-border-radius: 1rem;
}

.field_textArea, 
.field_textArea_question {
  background-color: transparent;
  font-size: 25px;
  color: white;
  border: none;
}

.field_textArea::placeholder,
.field_textArea_question::placeholder {
  background-color: transparent;
  font-size: 25px;
  color: white;
  border: none;
}

.background_button {
  background-color: rgba(0, 131, 221, 1);
  color: white;
}

.background_floating {
  color: #020a1887;
}

.title {
  width: 100%;
  color: #F0F0F0;
  background-color: #2E3F5C;
  font-size: 30px;
  font-weight: bold;
  padding: 0.5em;
  text-align: center;
}

.intro,
.companydesc,
.problemcontext,
.challengecase,
.problemanalysis,
.proposedsolution,
.solution,
.impacts,
.lessons,
.conclusion,
.videoCase {
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: #020A18;
  width: 100%;
  height: 100%;
}

.conclusionBox {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.subtitles {
  font-size: 25px;
  color: rgb(113, 96, 193);
  font-weight: bold;
}

.acordionBody {
  background-image: white;
}

.acordionItem {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.accordion_header {
  margin: 0;
}

.navBar_menu {
  background-color: #f8f9fa;
  width: 20%;
  height: 470px;
  font-size: 15px;
  font-weight: 400;
  border: 2px solid rgba(0, 131, 221, 1);
}

.nabBar_topics {
  padding: 1em;
  border-radius: .5em;
  background-color: white;
  border: 5px black;
  font-size: large;
  width: 80%;
  height: 470px;
  background: linear-gradient(100deg, rgba(0, 131, 221, 1) 0%, rgba(0, 201, 183, 1) 100%);
  border: 2px solid white;
}

.navBar_boxTexts {
  margin: 0 auto;
  font-size: 20px;
  border: none;
  background-color: transparent;
  color: white;
  width: 95%;
}

.navBar_text_menu {
  color: rgba(0, 131, 221, 1);
  font-weight: 500;
}

.navBar_text_menu:hover {
  color: rgba(0, 131, 221, 1);
  font-weight: 700;
}

.adjust_top_bottom {
  width: 80%
}

.text_question {
  background-color: transparent;
  border: none;
  color: white;
}

.textP {
  font-size: .7em;
  margin-top: 2%;
  color: white;
}

.title_videoCase {
  font-size: 25px;
  color: black;
  font-weight: bold;
}