.card_config {
    cursor: pointer;
    background-color: white;
}

.selected_card {
    border: 3px solid #265287;

}

.templateImage {
    width: 200px;
}