.projects_title {
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  color: white;
}

.choices {
  padding: 5%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.choice_circle {
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.circle_1 {
  background-image: linear-gradient(to top, rgb(11, 113, 238), rgba(11, 113, 238, 0.5));
}

.circle_2 {
  background-image: linear-gradient(to top, rgb(242, 195, 6), rgba(242, 195, 6, 0.5));
}

.circle_3 {
  background-image: linear-gradient(to top, rgb(26, 154, 83), rgba(26, 154, 83, 0.5));
}

.circle_4 {
  background-image: linear-gradient(to top, rgb(111, 69, 27), rgba(111, 69, 27, 0.5));
}

.row_icons_scorm {
  display: flex;
  justify-content: center;
  font-size: 120%;
}

.choice_texts {
  margin-top: 10%;
  color: white;
}

.icon_circle {
  color: white;
  font-weight: bold;
}

.icon_font {
  font-size: 250%;
  color: white;
}

.options {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: -10%;
  font-weight: bold;
  font-size: 30%;
  color: black;
}

.option_pointer {
  color: white;
  font-size: 380%;
  position: absolute;
  margin-top: 20.5rem;
}

.button {
  width: 15%;
  cursor: pointer;
  border-radius: 5%;
  margin-bottom: 1%;
  background-color: #012c61a3;
  border: 1px solid #012c61a3;
  color: white;
  padding: .25%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.button:hover {
  transform: scale(1.03);
  background-color: #0253a3ad;
}

.buttons {
  background-image: linear-gradient(to top, rgb(11, 113, 238), rgba(11, 113, 238, 0.5));
  width: 47%;
  padding: 1%;
  border-radius: 5px;
  color: white;
  margin-left: 3%;
  font-size: 30%;
}

.button_file {
  border: 1px solid rgb(3, 101, 137);
  background-image: linear-gradient(to top, rgb(11, 113, 238), rgba(11, 113, 238, 0.5));
}

.button_mode {
  border: 1px solid rgb(179, 158, 4);
  background-image: linear-gradient(to top, rgb(242, 195, 6), rgba(242, 195, 6, 0.705));
}

.button_formmat {
  border: 1px solid rgb(17, 133, 6);
  background-image: linear-gradient(to top, rgb(26, 154, 83), rgba(26, 154, 83, 0.5));
}

.div_buttons {
  padding-left: 1%;
}

.choiced {
  align-self: flex-end;
}

.mainMenu {
  background-image: url("../images/bgMenu.jpg");
  color: #fff;
  min-height: 100%;
  background-size: cover;
}

.menu_item {
  margin: 0 2em 4em;
  min-width: 280px;
  background-color: #012c61a3;
  max-width: 394px;
  max-height: 370px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  border-radius: 33px;
  transition: all 0.3s ease;
  cursor: pointer;
  padding-bottom: 15px;
}

.menu_item_disabled {
  margin: 0 .8em 4em;
  min-width: 290px;
  background-color: #010f20a3;
  max-width: 394px;
  max-height: 370px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  border-radius: 33px;
  transition: all 0.3s ease;
  padding-bottom: 15px;
}

.menu_item:hover {
  transform: scale(1.03);
  background-color: #0253a3ad;
}

.mainMenu h1 {
  text-transform: uppercase;
  font-size: 2em;
}

.mainMenu p {
  font-size: 10.5px;
  padding: .5em 1em;
}

.menu_title {
  text-transform: uppercase;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 2em;
  padding: .5em 0;
}

.menu_dropFiles {
  background-color: #012c61a3;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  border-radius: 10px;
  border: 2px dashed #ffffffad;
  margin-bottom: 2.5em;
}

.menu_dropFiles img {
  padding: 1.5em 0 0.5em 0;
}

.menu_dropFiles p {
  font-size: 20px;
}

.mouseOverSelecione {
  color: #fff;
  border-bottom: 1px solid #fff;
  transition: all 0.3s ease;
}

.mouseOverSelecione:hover {
  text-decoration: none;
  font-weight: bold;
  color: #fff;
  transform: scale(1.3);
}

.texts {
  font-weight: 600;
  font-size: 15px;
  color: white;
}

.textsLink {
  text-decoration: underline;
  cursor: pointer;
}