.custom_bar {
    width: 70em;
    height: 3em;
    background-image: linear-gradient(to bottom, #265287, #173b74);
    margin: 0 auto;
}

.top_bar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
}

.bottom_bar {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slide_customization {
    border: 2px solid #265287;
    width: 70em;
    height: 35em;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.course_title {
    color: aliceblue;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    display: flex;
    padding-left: 10px;
}

.button_navigation {
    background-color: transparent;
    border: 0;
    font-size: 40px;
    color: aliceblue;
    padding-right: 15px;
}

.button_navigation:disabled {
    background-color: transparent;
    color: rgb(152, 149, 149);
}

.button_intro_carousel {
    background-color: transparent;
    border: none;
}

.button_intro_carousel:disabled {
    background-color: transparent;
    border: none;
}

.carousel_texts {
    color: #265287;
    font-size: 17px;
    padding: 1em 0;
}

.progressBarContainer {
    width: 15%;
    background-color: #98989f;
    height: 20px;
    margin-right: 20px;
    border-radius: 5px;
    position: relative;
}

.progressBar {
    height: 100%;
    background-color: #5e7085;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.progressBarEnd {
    border-radius: 5px;
}

.progressTextContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 700;
}

.menu_adjust {
    display: flex;
    justify-content: end;
}