
.form_parameters {
    width: 13.5em;
    height: 2.5em;
    color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }

  .adjust_width {
    width: 20em;
  }

  .btn_style {
    background-color: #f3b012e1;
    border: 1px solid #f3b012e1;
    border-radius: 6px;
    color: black;
    font-weight: bold;
    width: 17em;
    height: 2.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .btn_style:disabled {
    background-color: #f6da9ae1;
    border: 1px solid #f6da9ae1;
    color: rgb(143, 140, 140);
  }

  .form_field {
    width: 20em;
    color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }

  .option_check {
    color: #265287;
    border: 1px solid #265287;
  }
  
  .option_check:checked {
    background-color: #265287;
    border-color: #265287;
  }

  .progressContainer {
    height: 25px;
    border-radius: 15px;
    margin-top: 50%;
}

.subTitle {
    color: black;
    text-align: center;
    white-space: pre-line;
}