.icons {
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
}

.username {
    font-size: 30px;
}

.jobtitle {
    font-size: 20px;
    margin-top: -20px;
}

.coursename {
    font-size: 20px;
    font-weight: 400;
}

.divName {
    margin-top: -30px;
}

.iconsEnroll {
    cursor: pointer;
}

.input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 10%;
}

.inputHide {
    border: none;
    color: white;
    margin-left: -8%;
    background-color: transparent;
}



.zindex {
    z-index: 1000;
}

.input:focus {
    border-color: #007bff;
    outline: none;
}

.button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 100%;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.colSettingsDate {
    width: 100px;
}

.colSettings {
    width: 250px;
}