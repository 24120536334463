.input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 42%;
}

.input:focus,
.inputColor:focus {
    border-color: #007bff;
    outline: none;
}

.inputColor {
    width: 3%;
    cursor: pointer;
    border-radius: 0;
    padding: 4px;
    height: 40px;
    width: 40px;
}

.buttonX {
    background-color: transparent;
    cursor: pointer;
    color: #007bff;
    font-size: 20px;
    border: none;
    font-weight: bold;
}

.buttons {
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 20%;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.companyLogo {
    width: 150px;
    cursor: pointer;
}

.links_list {
    display: flex;
    list-style: none;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.backImage {
    background-color: rgb(219, 219, 219);
    border: 1px solid #808080;
    padding: 10px;
}