

.body_project {
    box-sizing: border-box;
    margin: 0 auto;
    background: #020A18;
    background-image: linear-gradient(to right, #094d83, #0e406d, #0f3458, #0e2743, #0c1c30, #0c1c30, #0c1c30, #0c1c30, #0e2743, #0f3458, #0e406d, #094d83);
    font-family: 'Open Sans', 'Trebuchet MS', 'Tahoma', 'Arial';
    font-weight: 300;
  }

  .accordion .card + .card {
    margin-top: 0;
  }
  
  .accordion .card {
    border-bottom: none; /* Remove a borda inferior de cada card */
  }
  
  .accordion .card:last-child {
    border-bottom: 1px solid #dee2e6; /* Adiciona uma borda inferior no último card, se necessário */
  }
  

.title {
    width: 100%;
    color: #F0F0F0;
    background-color: #2E3F5C;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.intro, .companydesc, .problemcontext, .challengecase, .problemanalysis, .proposedsolution, .solution, .impacts, .lessons, .conclusion {
    margin-top: 1em;
    font-size: 20px;
    padding-left: 1em;
    padding-right: 1.5em;
}

.conclusionBox {
    margin-top: 2em;
    border: 1px solid rgb(3, 101, 137);
    background-image: linear-gradient(to top, rgb(11, 113, 238), rgba(11, 113, 238, 0.5));
    border-radius: 10px;
    padding: 0.5em;
}

.subtitles {
    font-size: 25px;
    color: #0097ea;
    font-weight: bold;
}

.panel_group .panel{
  background-color: #fff;
  border:none;
  box-shadow:none;
  border-radius: 10px;
  margin-bottom:11px;
}

.panel .panel_heading{
  padding: 0;
  border-radius:10px;
  border: none;
}
.panel_heading a{
  color:#fff !important;
  display: block;
  border:none;
  padding:20px 35px 20px;
  font-size: 20px;
  background-color:rgb(236, 87, 102);
  font-weight:600;
  position: relative;
  color:#fff;
  box-shadow:none;
  transition:all 0.1s ease 0;
}
.panel_heading a:after, .panel_heading a.collapsed:after{
  content: "\f068";
  font-family: fontawesome;
  text-align: center;
  position: absolute;
  left:-20px;
  top: 10px;
  color:#fff;
  background-color:rgb(236, 87, 102);
  border: 5px solid #fff;
  font-size: 15px;
  width: 40px;
  height:40px;
  line-height: 30px;
  border-radius: 50%;
  transition:all 0.3s ease 0s;
}
.panel_heading:hover a:after,
.panel_heading:hover a.collapsed:after{
  transform:rotate(360deg);
}
.panel_heading a.collapsed:after{
  content: "\f067";
}
#accordion .panel_body{
  background-color:#Fff;
  color:#8C8C8C;
  line-height: 25px;
  padding: 10px 25px 20px 35px ;
  border-top:none;
  font-size:14px;
  position: relative;
}



