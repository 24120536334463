body {
  background-image: none;
  background-color: #f9f9f9;
  background-image: none;
}

.bg {
  background-color: #f9f9f9;
  color: #323850;
}

.secao {
  background-color: #ffffff;
  margin: 2em;
  min-height: 12em;
}


.call_to_action .area {
    padding-top: 60px;
  }
  
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(38,82,135);
    background: -moz-linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
    background: linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#265287",endColorstr="#265287",GradientType=1);
  }
  
  .call_to_action h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    font-weight: bolder;
    padding: 0.1em 0;
  }
  
  .call_to_action h6 {
    font-weight: normal;
  }
  
  .call_to_action p {
    font-size: 1.2rem;
  }
  
  .call_to_action .btn {
    margin: 1em 0;
  }
  
  /* Botões */
  .botoesChamadas .botoes a {
    width: 21em;
  }
  .botoesChamadas .btn_other {
    /*background-color: #323850;*/
    /*border-color: #ffffff;*/
    background-color: #B56482;
    color: #fff;
  }
  
  .botoesChamadas .btn_other:hover {
    /*background-color: #514E6C;*/
    background-color: #6E4F75;
    color: #ffffff;
  }
  
  .btn_primary, .btn_other {
    font-weight: 500;
    padding: 0.75rem 2rem;
    border-radius: 0.5em;
    text-decoration-line: none;
  }
  
  .btn_primary  {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  .btn_primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }

  .btn_other {
    background-color: #323850;
    border-color: #323850;
    color: #fff;
  }

  .btn_other:hover {
    background-color: #514E6C;
    border-color: #514E6C;
    color: #fff;
  }
  
  /* Animação de fade-in */
  .fadeIn {
    opacity: 1!important;;
    transform: translateY(0)!important;;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out!important;;
  }
  
  .hideFadeIn {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .cabecalho {
    z-index: 9999;
    position: relative;
  }
  .cabecalho h1, h2 {
    overflow: hidden;
  }
  .botoesChamadas {
    padding: 0;
    background-color: #323850;
    margin:0!important;
    color: #ffffff;
  }
  
  .botoesChamadas .botoes {
    padding: 0
  }
  
  .botoesChamadas {
    padding:1em 0; 
    margin-bottom: 11px;
  }
  
  .botoesChamadas h2 {
    margin-top: -7px; 
    font-weight: lighter; 
    padding: .5em 0
  }
  
  .text_left {
    text-align: left!important;
    padding: .2em .6em!important;;
  }

  .etapas {
    text-align: center;
    padding: 20px;
    background: #ffffff;
    border-radius: 2em;
    margin: 6em auto;
    max-width: 1600px;
    /* border: 1px solid #d2d3d9;*/
  }

  .etapas h2{padding: .5em 0}


  .circle_image img {
    height: 7em;
    margin-top: -71px;
  }

  .pBreve {
    background-color: #E8EFFF;
    padding: .5em;
    border-radius: 1em;
    display: block;
    width: 8em;
  }

  .vertical {
    border: 0;
    margin: 0;
    border-left: 1px solid #323850c4;
    height: 7em;
    float: left;
}

.tooltip_term {
  text-decoration: underline;
  cursor: pointer;
  /* background-color: #eaa8cd4f; */
  /* padding: 0.5em 1em; */
  /* border-radius: 2em;*/
}

  .d_none {
    display: none !important;
  }

  .p_2 {
    padding: 0.5rem !important;
  }

  
 .area {
    margin: 0 auto;
  }
  
  @media (min-width: 576px) {
    .area {
      max-width: 540px;
    }
    .d_sm_none {
      display: none !important;
    }
    .d_sm_block {
      display: block !important;
  }
  }
  @media (min-width: 768px) {
    .area {
      max-width: 720px;
    }
    .text_md_left {
      text-align: left!important;
    }
    .text_md_right {
      text-align: right!important;
    }
    .col_md_8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col_md_7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col_md_6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col_md_4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col_md_3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col_md_2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .mb_md_0, .my_md_0 {
      margin-bottom: 0 !important;
    }
    .d_md_block {
      display: block !important;
      height: auto;
      width: auto;
    }
    .d_md_none {
      display: none !important;
    }
    
  }

  @media (min-width: 992px) {
    .area {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .area {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .area {
      max-width: 1320px;
    }
  }

