

.nav_tabs_custom .nav_link_custom {
    background-color: #a8aaad;
    font-weight: bold;
    border-radius: 0;

  }

  .components_custom {
    background-color: #f8f9fa;
    border: 1px solid gainsboro;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .nav_custom {
    background-color: #f8f9fa;
  }

  .nav_item a {
    color: #265287;;
  }

  .main_background {
    margin: auto 0;
    padding: .1em !important;
    background-image: url("../../images/bgMenu.jpg");
    color: #fff;
    min-height: 500px;
    background-size: cover;
  }