
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,600;0,900;1,300;1,600&display=swap');

body {
  background-color: #f6fcff;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif !important;
}

a,
nav button {
  text-decoration: none;
  background-color: transparent;
  border: none;
  transition: 0.4s;
  font-size: 1em;
  cursor: pointer;
}


.btn {
  background-color: #1a8918;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  width: 120px;
  font-weight: bold;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
}

.btn.btn-dark {
  background-color: #000;
  border-radius: 0;
}

.btn.btn-outline {
  background-color: transparent;
  border-radius: 0;
  color: #000;
  border: 1px solid #000;
  padding: 7px 30px;
}

.btn:hover {
  background-color: #0f730c;
  color: #fff;
}

.btn.btn-outline:hover {
  background-color: #000;
}

.btn.btn-danger:hover {
  background-color: #dc3545;
}

button[disabled] {
  background-color: #aaa;
}

.container .error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

textarea  {
  width: 50em;
}

.form-control {
  width: 100em;
}

