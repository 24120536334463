.carousel_icon {
    color: #265287;
    font-size: 50px;
  }

  .carousel_icon_disabled {
    color: rgb(208, 207, 207);
    font-size: 50px;
  }

  .slide_customization {
    border: 2px solid #265287;
    border-radius: 10px;
    height: 27em;
    width: 50em;
    margin: 0 auto;
  }

  .button_intro_carousel {
    background-color: transparent;
    border: none;
  }

  .button_intro_carousel:disabled {
    background-color: transparent;
    border: none;

  }

  .carousel_top {
    position: sticky; 
    bottom: 0; 
  }

  .carousel_texts {
    color: #265287;
    font-size: 17px;
    padding: 1em 0; 
  }

  .images_position {
    padding: 1em 0;
  }

  .carousel_inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 23em; 
}

.button_close {
    margin-right: 3%;
    margin-bottom: 5%;
    color: #265287;
    font-weight: bold;
}



