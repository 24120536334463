#menuButton {
  border: 0;
  background-color: transparent;
  color: white;
}

.menu_items {
  cursor: pointer;
}

.disabled_menu_item {
  opacity: 0.5;
  pointer-events: none;
}

.dropdown_item {
  font-size: 15px;
}

#slideMenu {
  max-height: 300px;
  width: 200px;
  overflow-y: auto;
}

.progressBarWidth {
  width: 15%;
}

.subtitles {
  color: white;
  font-weight: bold;
  position: absolute;
}

.adjust_title {
  padding-top: 1%;
  padding-bottom: 1%;
}

.icon_logo {
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.edit_logo {
  cursor: pointer;
}

.icon_delete_logo {
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

.input_title {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
}

.input_menu {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 1rem;
}

.progressBar {
  width: 50%;
}

.progressContainer {
  height: 20px;
  border-radius: 15px;
  margin-top: 1%;
}

.progressBarContainerAdjust {
  margin-top: 0%;
}

.icon_options {
  font-size: 50px;
  cursor: pointer;
  color: orange;
}

.text_delete_logo {
  width: 100px;
  margin-left: 3%;
  color: white;
  position: absolute;
}

.div_progressBar {
  align-items: center;
}

.text_disable_pb {
  margin-left: -11%;
  color: white;
  position: absolute;
  width: 130px;
}

.text_able_pb {
  margin-left: -12%;
  color: white;
  position: absolute;
  width: 150px;
}

.menu_edit {
  margin-top: -250%;
}

.floatingDiv {
  position: fixed;
  bottom: 0px;
  right: 5px;
  z-index: 500;
}

#button_option {
  background-color: transparent;
  border: none;
}

.adjust_buttonOption {
  margin-bottom: -20%;
}

.div_templates {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  background-color: white;
  border: 2px solid #265287;
}

.div_projectExport {
  overflow-y: auto;
  background-color: white;
  border: 2px solid #265287;
}

.dropdown_active {
  z-index: 9999;
}

.dropdown_inactive {
  z-index: 500;
}

.icons_menu {
  font-size: 50px;
}

.answer_assessment {
  font-size: 17px;
  border-radius: 2em;
  min-width: 10em;
  max-width: 10em;
  height: 3em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}

.text_showtowgether_white {
  color: white;
  font-size: 20px;
}

.text_showtowgether_black {
  color: black;
  font-size: 20px;
}

.videoContainer {
  position: relative;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.heightDivSlides {
  height: 40rem;
}

.heightMenuLat {
  height: 50em;
}

.subtitleContainer {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0px;
  border-radius: 5px;
  font-size: 37px;
  text-align: center;
}

.video_container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 80%; 

}

.video_container video {
  width: 80%;
  height: 80%;
}

.adjust_top {
  width: 80%
}

.adjust_bottom {
  width: 80%;
}

.adjust_slide {
  width: 80%;
  margin: 0 auto;
}

.custom_bar_80 {
  width: 80%;
}

.interactionDiv {
  position: absolute; 
  top: 0px; 
  left: 12%; 
  display: flex;
  align-items: start;
  justify-content: start;
}

.interactionIcon {
  font-size: 70px;
  cursor: pointer;

}

