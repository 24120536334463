.bg {
  background-color: #f9f9f9;
  color: #323850;
}
.secao {
  background-color: #ffffff;
  margin: 2em;
  min-height: 12em;
}

/* Animação de fade-in */
.fadeIn {
  opacity: 1!important;;
  transform: translateY(0)!important;;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out!important;;
}

.hideFadeIn {
  opacity: 0;
  transform: translateY(20px);
}

.call_to_action .area {
      /*position: relative;
      margin: 0 2em;
      padding: 0;
      z-index: 2;*/
      padding-top: 60px;
    }
    
    .call_to_action {
      background: url('../../images/funcionalidades.jpg') center/cover no-repeat!important;
      padding: 7em 0!important;
      /*background-position-y: bottom!important;*/
      position: relative;
      color: #ffffff;
      margin: 0;
      text-align: center;
      max-height: 22em;
    }
    
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(38,82,135);
      background: -moz-linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
      background: -webkit-linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
      background: linear-gradient(90deg, rgba(38,82,135,0.7539390756302521) 0%, rgba(38,82,135,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#265287",endColorstr="#265287",GradientType=1);
    }
    
    .call_to_action h1 {
      font-size: 3.5rem;
      margin-bottom: 1rem;
      font-weight: bolder;
      padding: 0.1em 0;
    }
    
    .call_to_action h6 {
      font-weight: normal;
    }
    
    .call_to_action p {
      font-size: 1.2rem;
    }
    
    .call_to_action .btn {
      margin: 1em 0;
    }

    
    .cabecalho {
      z-index: 9999;
      position: relative;
    }
    .cabecalho h1, h2 {
      overflow: hidden;
    }

    .d_none {
      display: none !important;
    }
    
    .p_2 {
      padding: 0.5rem !important;
    }
    
    
    .area {
      margin: 0 auto;
      /* margin-left: 4em;*/
    }

    .text_left {
      text-align: left!important;
    }

    
    .customAccordionHeader {
      /*display: flex; -- Atualizado 20231002*/
      /*justify-content: space-between; -- Atualizado 20231002*/
      /*align-items: center; -- Atualizado 20231002*/
      margin: 0;/*Atualizado 20231002*/
      padding: 0;/*Atualizado 20231002*/
    }

    .containerCustom {
      margin-bottom: 0!important;
      min-height: auto!important;
    }

    .customAccordionHeader::before {
      content: "\f078"; 
      font-family: FontAwesome; 
      margin-right: 10px; 
    }

    .customAccordionHeader::before {
      display: none!important;
    }

    .ultimaSanfona{
      padding-bottom: 3em;
    }
    .sanfona h1{
      margin: .5em 0;
      text-align: center; /*Atualizado 20231002*/
    }

    .videoYoutube {
      text-align: center;
    }

    
    @media (min-width: 576px) {
      .area {
        max-width: 540px;
      }
      .d_sm_none {
        display: none !important;
      }
      .d_sm_block {
        display: block !important;
      }
      .videoYoutube iframe {
        max-width: 54em;
        min-width: 31em;
        height: 100%;
      }
      .videoYoutube {
        height: 19em;
      }
      
    }
    @media (min-width: 768px) {
      .area {
        max-width: 720px;
      }
      .videoYoutube iframe {
        max-width: 54em;
        min-width: 36em;
      }
      .videoYoutube {
        height: 22em;
      }
      
      .text_md_left {
        text-align: left!important;
      }
      .text_md_right {
        text-align: right!important;
      }
      .col_md_8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      .col_md_7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col_md_6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col_md_4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      .col_md_3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col_md_2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .mb_md_0, .my_md_0 {
        margin-bottom: 0 !important;
      }
      .d_md_block {
        display: block !important;
        height: auto;
        width: auto;
      }
      .d_md_none {
        display: none !important;
      }
      
    }
    
    @media (min-width: 992px) {
      .area {
        max-width: 960px;
      }
      .videoYoutube iframe {
        max-width: 54em;
        height: 100%;
      }
      .videoYoutube {
        height: 22em; 
      }
    }
    @media (min-width: 1200px) {
      .area {
        max-width: 1140px;
      }
      .videoYoutube iframe {
        min-width: 46em;
        max-width: 54em;
        height: 100%;
      }
      .videoYoutube {
        height: 28em; 
      }
    }
    @media (min-width: 1400px) {
      .area {
        max-width: 1320px;
      }
      .videoYoutube iframe {
        min-width: 54em;
        max-width: 54em;
        height: 100%;
      }
      .videoYoutube {
        height: 32em;
      }
    }
    
    