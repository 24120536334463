.option_check {
    color: #265287;
    border: 1px solid #265287;
  }
  
  .option_check:checked {
    background-color: #265287;
    border-color: #265287;
  }

  .btn_style {
    background-color: #f3b012e1;
    border: 1px solid #f3b012e1;
    border-radius: 6px;
    color: black;
    font-weight: bold;
    width: 17em;
    height: 2.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .texts_format {
    color: #265287;
    font-weight: 500;
  }

  .progressBar {
    width: 50%;
  }
  
  .progressContainer {
    height: 20px;
    border-radius: 15px;
    margin-top: 1%;
  }
  
  .progressBarContainerAdjust {
    margin-top: 0%;
  }