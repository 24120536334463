
.body_project {
    background-color:  #f4f4f4;;
    min-height: 500px;
}

.backAba {
    background-color: white;
    margin-top: 0 !important;
    padding: 0 !important;
    min-height: 460px;
}

.abaItems {
    font-size: 1em;
    font-weight: 500;
    color: #265287;
}