.icons_question {
    font-size: 25px;
    cursor: pointer;
}

.icons_alternative {
    font-size: 20px;
    cursor: pointer;
}

.icon_color_white {
    color: white;
}

.icon_color_black {
    color: black;
}

.new_alternative {
    margin-top: 1rem;
    margin-left: 90px;
    cursor: pointer;
}

  .btn_style {
    margin-top: 1rem;
    background-color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 15em!important;
    height: 2.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .btn_excludeQuestion {
    border-radius: 4px;
    width: 13em;
    height: 2em;
    cursor: pointer;
  }

  .textArea {
    outline: none; 
    box-shadow: none; 
}

.assessmentDivRef {
  background-color: transparent;
}
