.round_pill {
    background-color: white;
    border-radius: 20px;
    border: 3px solid #265287;
    height: 2em;
    width: 12em;
  }

  .text_plan {
    color: #265287;
    font-weight: bold;
  }

  .my_plan {
    color: #265287;
    font-weight: bold;
  }