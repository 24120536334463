/* Animação de fade-in */
.fadeIn {
  opacity: 1 !important;
  ;
  transform: translateY(0) !important;
  ;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out !important;
  ;
}

.hideFadeIn {
  opacity: 0;
  transform: translateY(20px);
}

/* Estilos para o footer */
.footer {
  background-color: #f2f2f2;
  padding: 2em;
  width: 100%;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.footer ul {
  text-decoration: none !important;
  font-weight: 600;
}

.textColor {
  color:  #0253a3ad;
}

.footer-links li {
  margin: 0 1em;
}

.footer-links a {
  color: var(--c_dark);
  text-decoration: none;
  font-weight: 500;
}

.footer a {
  text-decoration-line: none;
}

.footer-links a:hover {
  color: var(--c_medium);
}

.footer .img_fluid {
  max-height: 3em;
  ;
}

.footer figcaption {
  font-size: 13px;
}

.footer h5 {
  font-size: 14px;
  padding: 5px 0 0 0;
  max-height: 1px;
  color: #32385087;
}

.ul_ {
  list-style-type: none;
  padding: 1em;
}


@media (min-width: 768px) {
  .text_md_left {
    text-align: left !important;
  }

  .text_md_right {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .col_md_8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col_md_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.area {
  margin: 0 auto;
}

@media (min-width: 576px) {
  .area {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .area {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .area {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .area {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .area {
    max-width: 1320px;
  }
}

.img_fluid_logo {
  width: 40px;
}

.img_fluid_paddle {
  width: 80px;
}