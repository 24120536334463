.div_choosefile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  background-image: url("../../images/bgMenu.jpg");
  background-size: cover;
  min-height: 100%;
}

.register {
  text-align: center;
}

.register p {
  color: #aaa;
}

.row_items {
  justify-content: center;
  text-align: center;
}

.btn_style {
  background-color: #4785d1;
  border: 1px solid #ffffff;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  width: 25em;
  height: 2.5em;
}

.btn_google {
  background-color: white;
  border: 1px solid #265287;
  border-radius: 6px;
  color: #265287;
  font-weight: bold;
  width: 25em;
  height: 2.5em;
}

.form_field {
  width: 25em;
  height: 2.5em;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.form_coupon {
  width: 22em;
  height: 2.5em;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.form_checked {
  width: 25em;
  height: 2.5em;
  text-align: start;
  text-indent: 10px;
}

.icon_user {
  color: #ffffff;
  font-size: 90px;
}

.texts {
  color: #ffffff;
}

.textsHyperlink {
  color: #53d5f9;
  font-weight: bold;
}

.box_check {
  color: #265287;
  border: 1px solid #265287;
}

.box_check:checked {
  background-color: #265287;
  border-color: #265287;
}

.form_checked_text {
  align-items: center;
}

.actual_choice {
  color: #265287;
}

.option_check {
  color: #265287;
  border: 1px solid #265287;
}

.option_check:checked {
  background-color: #265287;
  border-color: #265287;
}

.plans {
  border-radius: 6px;
  border: 1px solid #265287;
  padding: 2%;
}

.alert_costumization {
  background-color: #265287;
  border-color: black;
  color: white;
}

.info_boxes {
  position: absolute;
  margin-left: 35%;
  margin-top: 2em;
}

.info_boxes_preferences {
  position: absolute;
  margin-left: 23%;
  margin-top: -12.5%;
}

.adjust {
  margin-left: 26%;
}

.adjust_top {
  margin-top: -5%;
  margin-left: 25%;
}

.adjust_coupon {
  margin-top: 10%;
  margin-left: 5%;
  text-align: left;
}