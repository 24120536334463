.btn_style {
    background-color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 15em;
    height: 3.5em;
  }

  .btn_cancel {
    background-color: white;
    border: 1px solid #265287;
    border-radius: 6px;
    color: #265287;
    font-weight: bold;
    width: 15em;
    height: 3.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .btn_change {
    background-color: grey;
    border: 1px solid grey;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 15em;
    height: 3.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .round_pill {
    background-color: white;
    border-radius: 20px;
    border: 3px solid #265287;
    height: 2em;
    width: 10em;
  }

  .text_plan {
    color: #265287;
    font-weight: bold;
  }

  .line {
    border: 2px solid #a8aaad;
  }

  .progress_customized {
    border: 1px solid #265287;
    border-radius: 0;

  }

  .alert_costumization {
    background-color: #265287;
    border-color: black;
    color: white;
  }

  .icon_info {
    color: #265287;
  }

