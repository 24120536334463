.bi-three-dots-vertical{
  color: white;
  vertical-align: middle;
  font-size: 20px;
}

.bi-caret-down-fill{
  vertical-align: middle;
  font-size: 15px;
}

.bi-chevron-double-down {
  color: white;
}

.btn-light {
    border-radius: 0;
    width: 30px;
    height: 30px;
    background-color: white;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-toggle {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.custom-menu {
  position: absolute;
  top: 100%;
  z-index: 100;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 150px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
}

.custom-menu li {
  padding: 0;
}

.custom-menu li button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.custom-menu li button:hover {
  background-color: #f8f9fa;
  color: black;
}

.custom-dropdown.open .custom-menu {
  opacity: 1;
  max-height: 300px;
}

.dropdown-item{
  color: black;
}




