.icons {
    font-size: 30px;
    cursor: pointer;
}

.iconButton {
    background-color: transparent;
    border: none;
}

.inputStyle {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.inputStyle:focus {
    border-color: #007bff;
    outline: none;
}

.labelStyle {
    width: 7%;
    font-weight: 500;
}

.authorText {
    margin-left: 1%;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 30%;
}

.textReturn {
    color: black;
    font-size: 16px;
    margin-left: -10px;
}

.div_informations {
    margin-top: -30px;
}

.nav_link {
    color: #495057;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: color 0.3s, background-color 0.3s;
    text-decoration: none;
}

.nav_link:hover {
    color: #fff;
    background-color: #007bff;
}

.nav_link.active {
    color: #fff;
    background-color: #007bff;
}

.nav_link::after {
    color: #fff;
    background-color: #007bff;
}

.nav_link_active {
    color: #fff;
    background-color: #007bff;
    font-weight: bold;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}