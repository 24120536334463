.nav_bar {
  background-color: transparent;
}

.menu_items {
  display: flex;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: auto 0 0;
  background-color: rgb(223, 223, 223);
  font-weight: 500;
  cursor: pointer;
  color: black;
}

.menu_item_active {
  background-color: white;
  font-weight: bold;
  color: black;
}