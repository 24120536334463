.bg {
  color: #323850;

}

.secao {
  background-color: #ffffff;
  margin: 2em 0;
  min-height: 12em;
}


.call_to_action .area {
  /*position: relative;
    margin: 0 2em;
    padding: 0;
    z-index: 2;*/
  padding-top: 60px;

}

.call_to_action {
  background: url('../../images/plano.jpg') center/cover no-repeat !important;
  padding: 7em 0 !important;
  background-position-y: bottom !important;
  position: relative;
  color: #ffffff;
  margin: 0;
  text-align: center;
  max-height: 30em;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(38, 82, 135);
  background: -moz-linear-gradient(90deg, rgba(38, 82, 135, 0.7539390756302521) 0%, rgba(38, 82, 135, 0) 100%);
  background: -webkit-linear-gradient(90deg, rgba(38, 82, 135, 0.7539390756302521) 0%, rgba(38, 82, 135, 0) 100%);
  background: linear-gradient(90deg, rgba(38, 82, 135, 0.7539390756302521) 0%, rgba(38, 82, 135, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#265287", endColorstr="#265287", GradientType=1);
}

.call_to_action h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: bolder;
}

.call_to_action h6 {
  font-weight: normal;
}

.call_to_action p {
  font-size: 1.2rem;
}

.call_to_action .btn {
  margin: 1em 0;
}

/* Botões */
.botoesChamadas .btn_other {
  /*background-color: #323850;*/
  /*border-color: #ffffff;*/
  background-color: #B56482;
  color: #fff;
  width: auto !important;
}

.botoesChamadas .btn_other:hover {
  /*background-color: #514E6C;*/
  background-color: #6E4F75;
  color: #ffffff;
}


.btn_primary,
.btn_other {
  font-weight: 500;
  padding: 0.75rem 2rem;
  border-radius: 0.5em;
  text-decoration-line: none;
  white-space: nowrap;
}

.btn_primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  white-space: nowrap;
  /*min-width: 20em;*/
}

.btn_primary_selected {
  background-color: #0056b3;
  border-color: #0056b3;
  color: #fff;
  font-weight: 700;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  position: relative;
  z-index: 2;
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.btn_primary_selected:hover {
  background-color: #004494;
  border-color: #004494;
}

.btn_premium {
  padding: 0.25rem;
  border-radius: 0.5em;
  text-decoration-line: none;
  white-space: nowrap;
}

.rowPremiumBtns {
  margin-top: -9%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.adjust_primary {
  width: 15em;
}

.btn_primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn_other {
  background-color: #323850;
  border-color: #323850;
  color: #fff;
}

.btn_other:hover {
  background-color: #514E6C;
  border-color: #514E6C;
  color: #fff;
}

/* Animação de fade-in */
.fadeIn {
  opacity: 1 !important;
  ;
  transform: translateY(0) !important;
  ;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out !important;
  ;
}

.hideFadeIn {
  opacity: 0;
  transform: translateY(20px);
}

.cabecalho {
  z-index: 9999;
}

.banner {
  margin: auto 2em !important;
}

.cabecalho h1,
h2 {
  overflow: hidden;
}

.botoesChamadas {
  padding: 0;
  background-color: #323850;
  margin: 0 !important;
  color: #ffffff;
}

.botoesChamadas .botoes {
  padding: 0
}

.botoesChamadas {
  /* background-color: #323850;
    margin: 2em 0;
    color: #ffffff;*/
  padding: 1em 0;
  margin-bottom: 11px;
}

.botoesChamadas h2 {
  margin-top: -7px;
  font-weight: lighter;
  padding: .5em 0
}

/* ÍCONES */
.check_icon {
  /*content: '\2713';  Fallback para navegadores que não suportam SVG */
  color: green;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.cross_icon {
  /*content: '\2717';  Fallback para navegadores que não suportam SVG */
  color: #3238508c;
  display: inline-block;
  width: 1em;
  height: 1em;
}


.check_icon svg,
.cross_icon svg {
  width: 1em;
  /* Ajuste o tamanho conforme necessário */
  height: 1em;
  /* Ajuste o tamanho conforme necessário */
  vertical-align: middle;
  /* Alinhar o ícone verticalmente */
}

.check_icon svg path {
  stroke: #00C6C0;
  stroke-width: 4px;
  fill: none;
}


.cross_icon svg path {
  stroke: currentColor;
  stroke-width: 2;
  fill: none;
}

.planos {
  width: 21em;
}

.categoria {
  margin-left: 1em;
  width: 21em;
  text-align: left !important;
}

.parte,
.partes {
  margin-bottom: -17px;
}


.tabela h1 {
  padding: 1em 0.5em .5em 0.5em;
}

.tabela thead h1 {
  padding: 0;
}

.tabela thead p {
  padding: 0 0 1em 0;
}

.tabela thead summary {
  margin: 0.5em 0 1em 0;
  padding: 0.5em;
  list-style-type: none;
  cursor: default !important;
  padding-bottom: 2em;
  /* font-weight: 100; */
  /*line-height: 1.4rem;*/
}

.tabela h3 {
  background-color: #323850;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 100;
  padding: 0.2em;
  margin: 0;
  border-radius: 1em;
  width: 101%;
  transform: translateX(-1px);
  font-weight: bold;
}

.planos,
.categoria {
  border-left-color: #ffffff !important;
  border-right-color: #ffffff !important;
  vertical-align: middle !important;
  text-align: center;
}

.p_ {
  font-size: 1rem;
  font-weight: 100;
}

.catAdm {
  padding: 1rem 3em !important;
  display: table-cell;
  vertical-align: middle;
}

.tooltip_term {
  text-decoration: underline;
  cursor: pointer;
  /* background-color: #eaa8cd4f; */
  /* padding: 0.5em 1em; */
  /* border-radius: 2em;*/
}

/* Botão Ativado */
.activeButton {
  background-color: #323850;
  color: #ffffff;
  border: 1px solid #323850;
  padding: 0.5em;
}

.borderLeft {
  border-radius: 1em 0 0 1em;
  min-width: 7em;
}

.borderRight {
  border-radius: 0 1em 1em 0;
  min-width: 7em;
}

/* Botão Desativado */
.inactiveButton {
  background-color: #ffffff;
  color: #323850;
  border: 1px solid #323850;
  padding: 0.5em;
}

/* Estilos básicos para tabelas */
.table_ {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}


/* Estilos para tabelas com bordas em todas as células */
.table_bordered {
  border: 1px solid #dee2e6;
}

/* Estilos para células da tabela */
.table_ th,
.table_ td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* Estilos para cabeçalho da tabela */
.table_ thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

/* Estilos para células de cabeçalho de tabela com bordas */
.table_bordered th,
.table_bordered td {
  border: 1px solid #dee2e6;
}

.table_.table_bordered tbody tr td:nth-child(4),
.table_.table_bordered thead tr th:nth-child(4) {
  background-color: #f6f8ffd4;
}

.table_.table_bordered thead tr th:nth-child(4) {
  border-radius: 2em 2em 0 0;
}


.table_.table_bordered tbody tr td:nth-child(3),
.table_.table_bordered thead tr th:nth-child(3) {
  background-color: #DBECEA;
}

.table_.table_bordered thead tr th:nth-child(3) {
  border-radius: 2em 2em 0 0;
}


.table_.table_bordered tbody tr td:nth-child(2),
.table_.table_bordered thead tr th:nth-child(2) {
  background-color: #f6f8ffd4;
}

.table_.table_bordered thead tr th:nth-child(2) {
  border-radius: 2em 2em 0 0;
}


/* Estilizando o primeiro <td> de cada <tr> */
.table_bordered tbody tr td:first-child {
  /* Seu estilo aqui */
  width: 25%;
  /* Defina a largura desejada */
}

/* Estilizando o segundo e terceiro <td> de cada <tr> */
.table_bordered tbody tr td:nth-child(2),
.table_bordered tbody tr td:nth-child(3),
.table_bordered tbody tr td:nth-child(4) {
  width: 25%;
}

/* Estilizando o primeiro <th> de cada <tr> */
.table_bordered thead tr th:first-child {
  width: 25%;
}

/* Estilizando o segundo e terceiro <th> de cada <tr> */
.table_bordered thead tr th:nth-child(2),
.table_bordered thead tr th:nth-child(3),
.table_bordered thead tr th:nth-child(4) {
  width: 25%;
}

.btn_group {
  padding: 0.3em 1em 2em 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bordaZ {
  border-bottom-color: #ffffff !important;
}

.bordaZ2 {
  border-top-color: #ffffff00 !important;
  border-bottom-color: #ffffff00 !important;
}

.bordaZ3 {
  border-top-color: #ffffff00 !important;
}

.brZ {
  border-radius: 0 0 2em 2em;
}

.text_left {
  text-align: left !important;
  padding: .2em .6em !important;
}

.titulo {
  font-size: 2.5em;
  font-weight: bold;
  margin-left: -.5em;
  margin-top: .5em;
}

.subtitulo {
  font-size: 1.5em;
  font-weight: 500;
  margin-left: -.7em;
}

@media (min-width: 768px) {
  .text_md_left {
    text-align: left !important;
  }

  .text_md_right {
    text-align: right !important;
  }

  .col_md_8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col_md_6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col_md_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

}

.area {
  margin: 0 auto;
  /* margin-left: 4em;*/
}

.premiumPlans {
  width: 100%;
}

.yourplan {
  color: #323850;
  font-weight: bold;
}

.borderTOP {
  border-top: none !important;
}

.borderBOTTOM {
  border-bottom: none !important;
}

.borderLEFT {
  border-left: none !important;
}

.borderRIGHT {
  border-right: none !important;
}

.cellTexts {
  font-size: 12px;
}

.cellSettings {
  background-color: transparent !important;
  width: 5% !important;
}



@media (min-width: 576px) {
  .area {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .area {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .area {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .area {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .area {
    max-width: 1320px;
  }
}