.option_check {
  color: #265287;
  border: 1px solid #265287;
}

.option_check:checked {
  background-color: #265287;
  border-color: #265287;
}

.form_parameters {
  width: 13.5em;
  height: 2.5em;
  color: #265287;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.form_parameters_width {
  width: 23.5em;
}

.form_field {
  width: 5em;
  height: 2em;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.btn_cancel {
  background-color: white;
  border: 1px solid #265287;
  border-radius: 6px;
  color: #265287;
  font-weight: bold;
  width: 11em;
  height: 2.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn_style {
  background-color: #f3b012e1;
  border: 1px solid #f3b012e1;
  border-radius: 6px;
  color: black;
  font-weight: bold;
  width: 17em;
  height: 2.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.texts_format {
  color: #265287;
  font-weight: 500;
}

.subTitle {
  color: white;
  text-align: center;
  white-space: pre-line;
}

.spinner_customize {
  background-color: #265287;
}