.icons {
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
}

.div_informations {
    margin-top: -30px;

}

.inputStyle {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.inputStyle:focus {
    border-color: #007bff;
    outline: none;
}

.labelStyle {
    width: 70%;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 50%;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%;
}

.thumbnail {
    width: 180px;
}

.thumbnailCircular {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #000000;
}

.deleteButton {
    background-color: transparent;
    cursor: pointer;
    color: #007bff;
    font-size: 20px;
    border: none;
    font-weight: bold;
}

.iconPerson {
    font-size: 2.5rem;
}

.userButton {
    width: 35%;
    padding: .3%;
    border-radius: 6px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 20px;
    border-radius: 8px;
    width: 30%;
    max-width: 700px;
    min-height: 400px;
}

.closeButton {
    margin-top: -20px;
    margin-right: -20px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 30px;
    color: #007bff;
}

.iconsEnroll {
    cursor: pointer;
}

.checkbox {
    border: 2px solid rgb(90, 89, 89);

}

.addEnroll {
    margin-top: -20px;
}

.filters {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 100%;
    outline: none;
    box-shadow: none;
}

.filters:focus,
.filterName:focus {
    outline: none;
    box-shadow: none;
}

.filterName {
    padding: 3px;
    border: 1px solid rgb(179, 178, 178);
    font-size: 16px;
    width: 100%;
    border-radius: 2px;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.scrollDiv {
    max-height: 300px;
    max-width: 600px;
    min-height: 300px;
    overflow-y: auto;
}

.scrollDivNames {
    padding-left: 2%;
    padding-top: 1%;
    max-height: 220px;
    max-width: 400px;
    min-height: 220px;
    overflow-y: auto;
}

.scrollDiv:hover,
.scrollDivNames:hover {
    border: 1px solid rgb(190, 190, 190);
}


.scrollDiv:hover::-webkit-scrollbar,
.scrollDivNames:hover::-webkit-scrollbar {
    width: 8px; 
    
}

.scrollDiv:hover::-webkit-scrollbar-thumb,
.scrollDivNames:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.boxes {
    color: #000000;
}