body {
  background-image: linear-gradient(to top, #7ca9c6, #3b6c8f);
  align-items: center;
}

.plan_texts {
  color: darkgrey;
  font-weight: 700;
  font-size: 1.1rem;
}

.my_projects {
  color: black;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.icon_plan {
  color: darkgrey;
  font-weight: 700;
  font-size: 1.8rem;
  margin: -0.3rem
}

.icon_folder {
  font-weight: bold;
  font-size: 1.2rem;
}

.div_choosefile {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 30em;
}

.row_heigth {
  min-height: 34em;
}





