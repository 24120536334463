.button_menu {
  background-color: transparent;
  border: none;
}

.button_menu:hover,
.button_menu:active,
.button_menu:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.menu_icon {
  font-size: 30px;
}

.menu_items {
  background-color: none;
  border: 0;
}