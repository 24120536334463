.btn_style {
  background-color: #265287;
  border: 1px solid #265287;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  width: 15em;
  height: 2.5em;
}

.icon_info {
  color: #265287;
}

.alert_costumization {
  background-color: #265287;
  border-color: black;
  color: white;
}

.info_boxes {
  position: absolute;
  margin-left: 28%;
  margin-top: 0%;
}