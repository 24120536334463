.titulo {
  font-size: 2.5em;
  font-weight: bold;
  margin-left: .5em;
  margin-top: .5em;
}

.askedQuestion {
  font-weight: bold;
  color: #265287;
  font-size: 1.5rem;
  margin-left: 1rem;
}

.accordion {
  margin-top: 0;
}