.container {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
}

.addButton {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 15%;
}

.userButton {
    width: 30%;
    padding: .3%;
    border-radius: 6px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    max-width: 900px;
    min-height: 600px;
    max-height: 700px;
    overflow-y: auto;
}

.divContentEnrollment {
    overflow-y: auto;
    max-height: 550px;
}

.popupContent {
    display: flex;
    flex-direction: column;
}

.div_buttons {
    display: flex;
    justify-content: end;
    margin-top: -20px;
    width: 100%;
    margin: 0%;
}

.buttons {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 30%;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button {
    padding: 2px 20px;
    border: none;
    border-radius: 2%;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.disabledButton {
    background-color: #ccc;
    cursor: not-allowed;
}

.addButton {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 15%;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.icon {
    font-size: 30px;
    margin-right: 20px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input {
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%;
}

.input:focus {
    border-color: #007bff;
    outline: none;
}

.thumbnail {
    width: 150px;
    cursor: pointer;
}

.deleteButton {
    background-color: transparent;
    cursor: pointer;
    color: #007bff;
    font-size: 20px;
    border: none;
    font-weight: bold;
}

.enroll_infos {
    font-size: 16px;
}

.textsFormatted {
    color: black;
}

.filters {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 55%;
    outline: none;
    box-shadow: none;
    padding: 0%;
    font-weight: bold;
}

.filterDate {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 5%;
    outline: none;
    box-shadow: none;
    padding: 0%;
    font-weight: bold;
}

.adjust {
    width: 15%;
}

.filters:focus,
.filterName:focus,
.filterDate:focus {

    outline: none;
    box-shadow: none;
}

.filterDate {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 5%;
    outline: none;
    box-shadow: none;
    padding: 0%;
    font-weight: bold;
}

.filterName {
    padding: 3px;
    border: 1px solid rgb(179, 178, 178);
    font-size: 16px;
    width: 90%;
    border-radius: 2px;
    font-weight: bold;
}

.btnClose {
    border: none;
    background-color: transparent;
}

.filterDate {
    width: 50%;
}

.parent_element {
    position: relative;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.boxes {
    color: #000000;
    font-weight: bold;
}

.divEnrollments {
    overflow-y: auto;
    max-height: 350px;
    min-height: 250px;
}

.scrollDivCourses {
    max-height: 140px;
    max-width: 600px;
    min-height: 140px;
    overflow-y: auto;
    padding-left: 2%;
    padding-top: 1%;
}

.selectAll {
    padding-left: 8%;
}

.scrollDivNames {
    max-height: 400px;
    max-width: 400px;
    min-height: 400px;
    overflow-y: auto;
    padding-left: 2%;
    padding-top: 1%;
}

.scrollDivCourses:hover,
.scrollDivNames:hover {
    border: 1px solid rgb(190, 190, 190);
}


.scrollDivCourses:hover::-webkit-scrollbar,
.scrollDivNames:hover::-webkit-scrollbar {
    width: 8px; 
}

.scrollDivCourses:hover::-webkit-scrollbar-thumb,
.scrollDivNames:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}