:root {
  --c_medium: #004D73;
  --c_dark: #ff6766;
  --c_light: #AAFFFF;
}

.body_project {
  box-sizing: border-box;
  margin: 0 auto;
  background: rgb(63, 224, 208);
  background: -moz-linear-gradient(162deg, rgba(63, 224, 208, 1) 0%, rgba(174, 238, 238, 1) 50%, rgba(63, 224, 208, 1) 100%);
  background: -webkit-linear-gradient(162deg, rgba(63, 224, 208, 1) 0%, rgba(174, 238, 238, 1) 50%, rgba(63, 224, 208, 1) 100%);
  background: linear-gradient(162deg, rgba(63, 224, 208, 1) 0%, rgba(174, 238, 238, 1) 50%, rgba(63, 224, 208, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3fe0d0", endColorstr="#3fe0d0", GradientType=1);
  font-family: 'Open Sans', 'Trebuchet MS', 'Tahoma', 'Arial';
  font-weight: 300;
  }

  .subject {
    color: #FFFFFF;
  }

  .mensagem {
    color: black;
  }
  
  .word {
    color: red;
  }
  
  .question_feedback {
    max-width: 100%;
    padding: 1em 0 2em 0;
  }
  
  .question {
    font-weight: 600;
    font-size: 25px;
    color: var(--c_medium);
    margin-bottom: 1em;
  }

  .selected_alnl {
    background-color: var(--c_medium);
    color: #fff;
  }
  
  .question_feedback span {
    text-align: center;
  }
  
  .display-none {
    display: none;
  }
  
  .display-block {
    display: block;
  }

  .correct_answer,
.incorrect_answer {
  text-align: center;
  margin-top: 10px;
  width: 100%;
  color: rgb(255, 255, 255);
  font-weight: 600;
  padding: 2em;
  border-radius: 10em;
}

.correct_answer {
  background-color: #2B8682;
}

.incorrect_answer {
  background-color: #FB7F97;
}

.results {
  text-align: center;
  background-color: var(--c_medium);
  font-size: 25px;
  color: white;
  font-weight: bold;
  padding: 1em;
  margin-bottom: 2.5em !important;
  border-radius: 3em;
  max-width: 100%;
  cursor: pointer;
}
  
  .results:hover {
    transform: scale(1.02);
  }

  .label_assessment {
    background-color: #cfcbcb38;
    border-radius: 7em;
    min-width: 100%;
    padding: 1em;
    margin: 0.5em 0 1em;
    cursor: pointer;
  }

  .label_selected {
    background-color: #004D73;
    color: #fff;
    border-radius: 7em;
    min-width: 100%;
    padding: 1em;
    margin: 0.5em 0 1em;
    cursor: pointer;
  }
  
  .label_assessment:hover {
    background-color: #e6e6e6;
  }

  .submitButton {
    background-color: var(--c_dark);
    border: 0;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    width: 15%;
    margin-bottom: -1em;
    bottom: 0;
    padding: 20px;
    cursor: pointer;
    color: #ffffff;
    border-radius: 2em;
    min-width: 7em;
    max-width: 7em;
  }
  
  .submitButton:hover {
    transform: scale(1.05);
  }

  .project-content,
.form_assessment {
  width: 80%;
  background-color: #ffffff;
  padding: 1em 2em;
  border-radius: 1em;
  -moz-box-shadow: 0 0 1em #86868675;
  -webkit-box-shadow: 0 0 1em #86868675;
  box-shadow: 0 0 1em #86868675;
}

.form_assessment {
  margin-top: 2em;
  text-align: center;
  margin-bottom: 4em;
}

.project-content {
  white-space: pre-line;
}

.title-line {
  font-weight: bold;
  color: var(--c_medium);
  font-size: 30px;
}
  
  .project-content .title-line {
    text-align: center;
  }

  .h1_assessment {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px !important;
    border-radius: 1em;
    max-width: 82%;
  }
  
  .mensagem {
    color: black;
  }
  
  .p_assemssment {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mensagem {
    border-radius: 1em;
    padding: 2em;
    min-width: 80%;
    max-width: 80%;
    color: var(--c_medium);
    margin: 2em auto;
    font-size: 1.4rem;
    text-align: center;
    margin: 2em auto !important;
    background-color: #ffffff;
    border-radius: 1em;
    -moz-box-shadow: 0 0 1em #86868675;
    -webkit-box-shadow: 0 0 1em #86868675;
    box-shadow: 0 0 1em #86868675;
  }

  .footer-image {
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translateX(-50%);
    z-index: 9999;
    max-width: 100px;
  }
  
  #answerAssessment {
    background-color: rgb(63, 224, 208);
    border: 0;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    color: #ffffff;
    border-radius: 2em;
    min-width: 5em;
    max-width: 9em;
    display: flex;
    justify-content: center;
    align-items: center;
  }


#reviewVideo {
  background-color: #004D73;
  border: 0;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 2em;
  min-width: 5em;
  max-width: 9em;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .answerAssessmentVideo {
    font-size: 20px;
    border-radius: 5em;
    min-width: 10em;
    max-width: 10em;
  }
  
  .carousel-control-prev-icon {
    color: #FFFFFF;
  }
  
  .carousel-control-next-icon {
    color: #FFFFFF;
  }
  
  .custom_bar {
    width: 100%;
    background: #FB7F97;
    margin: 0 auto;
    box-shadow: 0 0 1em #344b4775;
  }
  
  .top_bar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
  }
  
  .bottom_bar {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .slide_customization {
    border: 2px solid #265287;
    width: 70em;
    height: 35em;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .course_title {
    align-items: center;
    justify-content: start;
    color: aliceblue;
    font-size: 20px;
    display: flex;
    width: 100%;
    padding-left: 10px;
  }
  
  .button_navigation {
    z-index: 900;
    background-color: transparent;
    border: 0;
    font-size: 40px;
    color: aliceblue;
    padding-right: 15px;
  }
  
  .button_navigation:disabled {
    background-color: transparent;
    color: rgb(152, 149, 149);
  }

  .button_intro_carousel {
    background-color: transparent;
    border: none;
  }
  
  .button_intro_carousel:disabled {
    background-color: transparent;
    border: none;
  }
  
  .carousel_texts {
    color: #265287;
    font-size: 17px;
    padding: 1em 0;
  }

  .progressBarContainer {
    background-color: #98989f;
    height: 20px;
    border-radius: 5px;
    position: relative;
  }
  
  .progressBar {
    height: 20px;
    background-color: #5e7085;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  
  .progressBarEnd {
    border-radius: 5px;
  }

  .progressTextContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 700;
  }
  
  .menu_adjust {
    width: 80%;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .menu_icon {
    font-size: 30px;
  }
  
  .logo {
    width: 15%;
  }

  .adjust_align {
    justify-content: center;
  }
  
  @media (min-width: 768px) {
    .text-md-right {
      text-align: right !important;
    }
  
    .text-md-left {
      text-align: left !important;
    }
  }
  
  :root {
    --bs-border-radius: 1rem;
  }

  .field_textArea,
  .field_textArea::placeholder
  {
    background-color: transparent;
    font-size: 25px;
    color: white;
    border: none;
  }

  .option_check {
    color: black;
  }

  .background_button {
    background-color:#FB7F97;
    color: white;
  }
  
  .background_floating {
    color: #FB7F97;
  }

  .title {
    width: 100%;
    color: #F0F0F0;
    background-color: #2E3F5C;
    font-size: 30px;
    font-weight: bold;
    padding: 0.5em;
    text-align: center;
  }
  
  .intro,
  .companydesc,
  .problemcontext,
  .challengecase,
  .problemanalysis,
  .proposedsolution,
  .solution,
  .impacts,
  .lessons,
  .conclusion,
  .videoCase  {
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: #020A18;
    width: 100%;
  }
  
  .conclusionBox {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  
  .subtitles {
    font-size: 25px;
    color: #FB7F97;
    font-weight: bold;
  }
  
  .acordionBox {
    background-image: white;
  }
  
  .acordionItem {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .accordion_header {
    margin: 0;
  }

  .navBar_menu {
    background-color: #f8f9fa;
    width: 20%;
    height: 470px;
    font-size: 15px;
    font-weight: 400;
    border: 2px solid rgba(63, 224, 208, 1)
  }
  
  .nabBar_topics {
    padding: 1em;
    border-radius: .5em;
    background-color: white;
    border: 5px black;
    font-size: large;
    width: 80%;
    height: 470px;
    background: linear-gradient(162deg, rgba(63, 224, 208, 1) 0%, rgba(174, 238, 238, 1) 50%, rgba(63, 224, 208, 1) 100%);
    border: 2px solid white;
  }
  
  .navBar_boxTexts {
    margin: 0 auto;
    font-size: 20px;
    border: none;
    background-color: transparent;
    color: black;
    width: 95%;
  }
  
  .navBar_text_menu {
    color: rgb(12, 146, 132);
    font-weight: 500;
  }

  .navBar_text_menu:hover {
    color: rgb(12, 146, 132);
    font-weight: 700;
  }
  
  .adjust_top_bottom {
    width: 80%
  }

  .textP {
    font-size: .7em;
    margin-top: 2%;
    color: black;
  }
  
  .title_videoCase {
    font-size: 25px;
    color: black;
    font-weight: bold;
  }