.boxChooseFile {
    margin: auto 0;
    border: 1px dashed white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 350px;
    width: 60%;
    padding: 0;
    margin-top: 6%;
}

.dragOverBox {
    transform: scale(1.03);
    background-color: #0253a3ad;
}

.icon_arrowUp {
    font-size: 400%;
    color: white
}

.title {
    color: white;
    font-weight: bold;
}

.subTitle {
    color: white;
    text-align: center;
    white-space: pre-line;
}

.button_file {
    border: 1px solid rgb(39, 189, 244);
    background-color: rgb(39, 189, 244);
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.buttons_control {
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 10px;
    border: none;
    font-size: 90%;
    width: 15%;
}

.button_cancel {
    color: white;
    background-color: rgb(170, 170, 170);
}

.button_confirm {
    color: black;
    background-color: rgb(242, 226, 3);
}

.progressBar {
    width: 50%;
}

.progressContainer {
    height: 25px;
    border-radius: 15px;
    margin-top: 35%;
}

.boxConverting {
    margin-top: 5%;
}

.successMessage {
    color: white;
    text-align: center;
    white-space: pre-line;
    margin-top: 13%;
}

.div_choosefile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34em;
    margin: auto 0;
    background-image: url("../images/bgMenu.jpg");
    background-size: cover;
    min-height: 100%;
}

.div_buttons {
    width: 60%;
    text-align: right;
}

.menu_item:hover {
    transform: scale(1.03);
    background-color: #0253a3ad;
}

.menu_title {
    text-transform: uppercase;
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 2em;
    padding: .5em 0;
    text-align: center;
}

.menu_dropFiles {
    background-color: #012c61a3;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    border-radius: 10px;
    border: 2px dashed #ffffffad;
    margin-bottom: 2.5em;
    width: 70%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.menu_dropFiles img {
    padding: 1.5em 0 0.5em 0;
}

.menu_dropFiles p {
    font-size: 20px;
}

.mouseOverSelecione {
    color: #fff;
    border-bottom: 1px solid #fff;
    transition: all 0.3s ease;
    text-decoration: none;
}

.mouseOverSelecione:hover {
    text-decoration: none;
    font-weight: bold;
    color: #fff;
    transform: scale(1.3);
}

.botao_fechar {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    cursor: pointer;
}

.text_file {
    color: #fff;
    font-weight: bold;
}

.text_extension {
    font-size: 18px;
}