.inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 100%;
}

.inputPassword {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
    width: 90%;
}

.eyeButton {
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 10%;
}

.input:focus {
    border-color: #007bff;
    outline: none;
}

.div_buttons {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    width: 50%;
    margin: 0%;
}

.buttons {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 30%;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.disabledButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 30%;
}

.button:hover {
    background-color: #0056b3;
}

.userButton {
    width: 30%;
    padding: .3%;
    border-radius: 6px;
}

.name {
    width: 50%;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 700px;
    min-height: 400px;
}

.popupContent {
    display: flex;
    flex-direction: column;
}

.inputGroup {
    margin-bottom: 15px;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.div_buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.button {
    padding: 2px 20px;
    border: none;
    border-radius: 2%;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.disabledButton {
    background-color: #ccc;
    cursor: not-allowed;
}

.addButton {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 10%;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.icon {
    font-size: 40px;
    margin-right: 20px;
}

.textsFormatted {
    color: black;
}

.icons {
    font-size: 25px;
    margin-right: 5px;
}

.thumbnail {
    width: 180px;
}

.thumbnailCircular {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #000000;
}

.iconPerson {
    font-size: 35px;
}

.filters, 
.filterDate {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 110%;
    outline: none; 
    box-shadow: none; 
    padding: 0%;
    font-weight: bold;
}

.filters:focus, 
.filterName:focus,
.filterDate:focus {
    
    outline: none;
    box-shadow: none;
}

.filterName {
    padding: 3px;
    border: 1px solid rgb(179, 178, 178);
    font-size: 16px;
    width: 90%;
    border-radius: 2px;
    font-weight: bold;
}

.btnClose {
    border: none;
    background-color: transparent;
}

.filterDate {
    width: 50%;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.divUsers {
    overflow-y: auto;
    max-height: 350px;
    min-height: 250px;
}

.container {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
}