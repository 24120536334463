.icons {
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
}

.divUsers {
    overflow-y: auto;
    max-height: 350px;
    min-height: 250px;
}

.divTitle {
    margin-top: -30px;
    font-size: 25px;
    font-weight: 700;
}

.textsFormatted {
    color: black;
}

.filterDate {
    width: 50%;
}

.formatTexts {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
}

.thumbnail {
    width: 180px;
}

.thumbnailCircular {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #000000;
}

.iconPerson {
    font-size: 35px;
}

.filters,
.filterDate {
    padding: 3px;
    border: none;
    font-size: 16px;
    width: 110%;
    outline: none;
    box-shadow: none;
    padding: 0%;
    font-weight: bold;
}

.filters:focus,
.filterName:focus,
.filterDate:focus {

    outline: none;
    box-shadow: none;
}

.filterName {
    padding: 3px;
    border: 1px solid rgb(179, 178, 178);
    font-size: 16px;
    width: 90%;
    border-radius: 2px;
    font-weight: bold;
}

.csvButton {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 10%;
}

.stickyHeader {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
}

.statusFilterDropdown {
    border: none;
    font-weight: bold;
}

.statusFilterDropdown:focus {
    outline: none;
    box-shadow: none;
}