.logo {
    width: 120px;
    margin-right: 5px;
  }

  .btn_style {
    background-color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    width: 10em;
    height: 2.5em;
  }

  .btn_cancel {
    background-color: white;
    border: 1px solid #265287;
    border-radius: 6px;
    color: #265287;
    font-weight: bold;
    width: 11em;
    height: 2.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .image {
    width: 150px;
  }

  .questionDialog {
    font-size: 18px;
  }

  .form_parameters {
    width: 13.5em;
    height: 2.5em;
    color: #265287;
    border: 1px solid #265287;
    border-radius: 6px;
    text-indent: 10px;
  }

  .form_rename {
    width: 230%;
  }

  .checkBox_style {
    color: #265287;
    border: 1px solid #265287;
  }

  .label_style {
    color: #265287;
  }

  .fullWidth {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: 1rem; 
  }