.navbar {
  display: flex;
  align-items: center;
  padding: 0.5em 2em;
  background-color: #f4f4f4;
  width: 100%;
}

.brand {
  font-size: 1.5em;
}

.brand span {
  font-weight: 900;
  text-transform: uppercase;
}

.links_list {
  display: flex;
  list-style: none;
}


.active {
  color: #265287;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
}

.active:hover {
  color: white;
  background-color: #265287;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
}

.selected {
  color: white !important;
  background-color: #265287;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
}

.not_active {
  text-decoration: none;
  font-weight: bold;
  color: #069ed9;
  font-size: 17px;
}

.logo {
  width: 180px;
}

.menu {
  color: #265287;
  font-weight: 500;
}

.container {
  display: flex;
  align-items: center;
}

.logoutButton {
  display: flex;
  align-items: center;
}

.logoutButton button {
  display: flex;
  align-items: center;
}

.flag {
  width: 2.5em;
}

.flags_list {
  border: none;
  background-color: transparent;
}

.beta {
  font-weight: bold;
  font-size: 1.5em;
  color: #B56482;
}

.container_adjust {
  height: 2em !important;
}

.show {
  display: block !important;
  /* Show the element when the "show" class is applied */
  visibility: visible !important;
  /* Make the element visible */
  opacity: 1 !important;
  /* Set opacity to 1 to fully show the element */
  max-height: none !important;
  /* Remove any maximum height set by Bootstrap */
  width: fit-content;
  margin: 0 auto;
}

.hide {
  visibility: hidden !important;
}

.thumbnailCircular {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000000;
  margin-right: 10px;
}

.iconPerson {
  font-size: 2rem;
  margin-right: 10px;
}