.assessment_setup {
    text-align: left;
    align-content: start;
}

.form_select {
    width: 50px;
}

form {
    justify-content: start;
}

