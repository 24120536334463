.btn_style {
    background-color: #f3b012e1;
    border: 1px solid #f3b012e1;
    border-radius: 6px;
    color: black;
    font-weight: bold;
    width: 17em;
    height: 2.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .button_quizz {
    border: 1px solid rgb(39, 189, 244);
    background-color: rgb(39, 189, 244);
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.option_check {
  color: #265287;
  border: 1px solid #265287;
}

.qty_question {
  color: black;
  font-weight: 500;
}

.form_parameters {
  width: 13.5em;
  height: 2.5em;
  color: #265287;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.optionCase_texts {
  color: black;
  margin-left: 1em;
}

.form_field {
  width: 17em;
  height: 2.5em;
  border: 1px solid #265287;
  border-radius: 6px;
  text-indent: 10px;
}

.closeTopic {
  font-size: large;
  font-weight: bolder;
  color: red;
  background-color: transparent;
  border: none;
}

.createTopic {
  font-size: large;
  font-weight: bolder;
  color: green;
  background-color: transparent;
  border: none;
}

.btn_plus {
  margin-left: 0.5em;
  margin-top: -1em;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 30px;
}

.icon_plus {
  display: flex;
  font-size: 30px;
  color: black;
}